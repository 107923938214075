import request from '@/utils/request';

const PATH = '/apin/user/v1';

// 회원 탈퇴 처리
export const withdrawUser = (reason: string) => request({
  url: `${PATH}/unregister`,
  method: 'PUT',
  data: {
    reason,
  },
});

// 이메일 인증 코드 보내기
export const generateEmailToken = () => request({
  url: `${PATH}/register/email/token/generate`,
  method: 'PUT',
})

// 이메일 인증 코드 확인
export const verifyEmailToken = (data: any) => request({
  url: `${PATH}/register/email/token/verify`,
  method: 'PUT',
  data,
})

// 초기 비밀번호 설정
export const setInitialPassword = (data: any) => request({
  url: `${PATH}/password/change-by-participant`,
  method: 'PUT',
  data,
})